* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  font-size: 14px;
}
h6{
  font-size: 16px;
  font-weight: 700;
}
.splash-main{
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #F3E461;
  position: relative;
  justify-content: center;
  align-items: center;
}
.logo-and-text-box{
  width: 550px;
  min-height: 200px;
  position: relative;
  flex-direction: column;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}
.bro{
  position: absolute;
  bottom: 0;

}

.leaves{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}


.main-section {
  width: 100%;
  position: relative;
}
.map-container {
  width: 100%;
  position: relative;
  background-color: rgba(117, 94, 1, 0.049);
  height: 100vh;
}
.search-container {
  width: 340px;
  min-height: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #f8f8f8;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 2;
  top: 70px;
  padding-bottom: 20px;
  left: 10px;
}

.logo-container {
  width: 100%;
  height: 70px;
  border: 1px solid rgba(129, 129, 129, 0.03);
  background: #fff;
  z-index: 2;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.result-summary-container {
  width: 384px;
  height: 650px;
  border-radius: 8px;
  flex-shrink: 0;
  background-color: hsl(0, 0%, 97%);
  overflow-y: hidden;
  right: 10px;
  position: absolute;
  z-index: 2;
  top: 30px;
}
.search-container-top {
  width: 100%;
  height: 45px;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  padding: 20px;
  top: 0;
  background-color: #3e3e3e;
  border-radius: 10px 10px 0px 0px;
}
.search-container-input {
  width: 90%;
  margin-top: 15px;
  outline: none;
  padding: 11px 11px 11px 12px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  background: #fff;
}
.map-container {
  position: relative; /* Make sure it's relative so the overlay is positioned correctly */
}

.map-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.198);
  pointer-events: none;
}

.dropdown-list {
  padding: 11px 12px 12px 11px;
  justify-content: center;
  width: 90%;
  margin-top: 20px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  background: #fff;
}
.button {
  min-width: 100px;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  transition: background-color 0.21s ease-in-out;

  background-color: #f3e461;
}
.button.blue{
  background-color: rgba(135, 206, 250, 0);
  border: black solid 1px;
}
.button:hover {
  background-color: #bdb03f;
}

.result-summary-header {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  top: 0;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  cursor: pointer;
  height: 30px;
  border-radius: 10px;
  border: 1px solid #999;
  background-color: #f4f4f4;
  transition: background-color 0.21s ease-in-out;
}
.button-container:hover {
  background-color: #bdb03f;
}
.result-summary-body {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 70px;
}
.result-body {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  min-height: 90px;
  padding-left: 10px;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.21);
  background: #fff;
}
.result-body-content{
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: space-between;
}

.main-overlay{
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.844);
  z-index: 10;
}

.network-error-box {
  width: 500px;
  height: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  background-color: white;
  position: relative;
  z-index: 11;
}
.empty-list-message{
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-header{
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.div1 {
  width: 200px;
  height: 100px;

}

.divContainer2 {
  width: 1300px;
  display: flex;
  margin-top: 200px;
  padding-left: 50px;
  justify-content: space-between;

}

.text1 {
  text-align: center;
  margin-top: 100px;
  font-size: 40px;
}
.footerbg {
  background-color:rgb(87, 87, 240);
  margin-top: 200px;
  width: 100%;
  height: 500px;
}
.textdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
h6 {
  font-size: 30px;
}
.footerdiv {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.btn1 {
  width: 150px;
  height: 50px;
  border: 2px solid #0064D9;
}
.btn2 {
  width: 150px;
  height: 50px;
  background-color: linear-gradient(#7D83FF, #0064D9);
}
.company-name-container {
  position: absolute;
  left: -100%; 
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}

nav .company-name-container {
  position: absolute;
  left: -100%; /* Start just outside of the logo area */
  top: 25%;
  z-index: 1;
  transform: translateX(-50%);
  transition: transform 0.8s ease-in-out;
  white-space: nowrap;
}

nav:hover .company-name-container {
  transform: translateX(640%); /* Slide in from the left, adjust as needed */
}