
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'tailwindcss/tailwind.css';

.nunito-normal {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
body {
  margin: 0;
  @apply font-nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
